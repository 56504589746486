<template>
    <div class="view-cases">
         <div :style="{ 'backgroundImage': `url(${require('../assets/images/view/cases/cases.png')})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'4rem'}"></div>
      <!-- <img class="bannerImg" src="../assets/images/view/cases/cases.png" alt=""> -->
      <div class="paragraphs">
           <div class="caseTitle">项目案例展示</div>
          <div>
            <el-tabs v-model="activeName">
                <el-tab-pane label="全部" name="first">
                    <div class="cases" v-for="(items,index) of pages" :key="index">
                        <div class="contents">
                     <div class="left">
                         <div class="image" :style="{ 'backgroundImage': `url(${items.subContent.left.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                          <div class="subContent">
                              <p class="subTitle">{{items.subContent.left.title}}</p>
                              <p class="content" v-for="(item,i) of items.subContent.left.contents" :key="i">{{item}}</p>
                          </div>
                     </div>
                     <div class="right">
                         <div class="top">
                             <div  v-for="(item,i) of items.subContent.right.contents" :key="i">
                                 <p class="title" style="font-size:0.16rem;font-weight:bold">{{item.title}}</p>
                                 <div class="images" :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover',}"></div>
                                 </div>
                         </div>
                         <div class="discription">{{items.subContent.right.discription}}
                             <span style="color:red">{{items.subContent.right.disContent}}</span>
                         </div>
                     </div>
                 </div>
                 </div>
                </el-tab-pane>
                <el-tab-pane label="制造领域" name="second">
                    <div class="cases" v-for="(items,index) of manufacture" :key="index">
                        <div class="contents">
                     <div class="left">
                         <div class="image" :style="{ 'backgroundImage': `url(${items.subContent.left.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                          <div class="subContent">
                              <p class="subTitle">{{items.subContent.left.title}}</p>
                              <p class="content" v-for="(item,i) of items.subContent.left.contents" :key="i">{{item}}</p>
                          </div>
                     </div>
                     <div class="right">
                         <div class="top">
                             <div  v-for="(item,i) of items.subContent.right.contents" :key="i">
                                 <p class="title" style="font-size:0.16rem;font-weight:bold">{{item.title}}</p>
                                 <div class="images" :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover',}"></div>
                                 </div>
                         </div>
                         <div class="discription">{{items.subContent.right.discription}}
                             <span style="color:red">{{items.subContent.right.disContent}}</span>
                         </div>
                     </div>
                 </div>
                 </div>
                </el-tab-pane>
                <el-tab-pane label="汽车领域" name="third">
                    <div class="cases" v-for="(items,index) of automobile" :key="index">
                        <div class="contents">
                     <div class="left">
                         <div class="image" :style="{ 'backgroundImage': `url(${items.subContent.left.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                          <div class="subContent">
                              <p class="subTitle">{{items.subContent.left.title}}</p>
                              <p class="content" v-for="(item,i) of items.subContent.left.contents" :key="i">{{item}}</p>
                          </div>
                     </div>
                     <div class="right">
                         <div class="top">
                             <div  v-for="(item,i) of items.subContent.right.contents" :key="i">
                                 <p class="title" style="font-size:0.16rem;font-weight:bold">{{item.title}}</p>
                                 <div class="images" :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover',}"></div>
                                 </div>
                         </div>
                         <div class="discription">{{items.subContent.right.discription}}
                             <span style="color:red">{{items.subContent.right.disContent}}</span>
                         </div>
                     </div>
                 </div>
                 </div>
                </el-tab-pane>
                <el-tab-pane label="农业领域" name="fourth">
                    <div class="cases" v-for="(items,index) of agriculture" :key="index">
                        <div class="contents">
                     <div class="left">
                         <div class="image" :style="{ 'backgroundImage': `url(${items.subContent.left.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                          <div class="subContent">
                              <p class="subTitle">{{items.subContent.left.title}}</p>
                              <p class="content" v-for="(item,i) of items.subContent.left.contents" :key="i">{{item}}</p>
                          </div>
                     </div>
                     <div class="right">
                         <div class="top">
                             <div  v-for="(item,i) of items.subContent.right.contents" :key="i">
                                 <p class="title" style="font-size:0.16rem;font-weight:bold">{{item.title}}</p>
                                 <div class="images" :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover',}"></div>
                                 </div>
                         </div>
                         <div class="discription">{{items.subContent.right.discription}}
                             <span style="color:red">{{items.subContent.right.disContent}}</span>
                         </div>
                     </div>
                 </div>
                 </div>
                </el-tab-pane>
                <el-tab-pane label="外包装领域" name="fifth">
                    <div class="cases" v-for="(items,index) of pack" :key="index">
                        <div class="contents">
                     <div class="left">
                         <div class="image" :style="{ 'backgroundImage': `url(${items.subContent.left.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                          <div class="subContent">
                              <p class="subTitle">{{items.subContent.left.title}}</p>
                              <p class="content" v-for="(item,i) of items.subContent.left.contents" :key="i">{{item}}</p>
                          </div>
                     </div>
                     <div class="right">
                         <div class="top">
                             <div  v-for="(item,i) of items.subContent.right.contents" :key="i">
                                 <p class="title" style="font-size:0.16rem;font-weight:bold">{{item.title}}</p>
                                 <div class="images" :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover',}"></div>
                                 </div>
                         </div>
                         <div class="discription">{{items.subContent.right.discription}}
                             <span style="color:red">{{items.subContent.right.disContent}}</span>
                         </div>
                     </div>
                 </div>
                 </div>
                </el-tab-pane>
                <el-tab-pane label="3C领域" name="sixth">
                    <div class="cases" v-for="(items,index) of area" :key="index">
                        <div class="contents">
                     <div class="left">
                         <div class="image" :style="{ 'backgroundImage': `url(${items.subContent.left.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'100%'}"></div>
                          <div class="subContent">
                              <p class="subTitle">{{items.subContent.left.title}}</p>
                              <p class="content" v-for="(item,i) of items.subContent.left.contents" :key="i">{{item}}</p>
                          </div>
                     </div>
                     <div class="right">
                         <div class="top">
                             <div  v-for="(item,i) of items.subContent.right.contents" :key="i">
                                 <p class="title" style="font-size:0.16rem;font-weight:bold">{{item.title}}</p>
                                 <div class="images" :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover',}"></div>
                                 </div>
                         </div>
                         <div class="discription">{{items.subContent.right.discription}}
                             <span style="color:red">{{items.subContent.right.disContent}}</span>
                         </div>
                     </div>
                 </div>
                 </div>
                </el-tab-pane>
           </el-tabs> 
          </div>
      </div>
    </div>
</template>
<script>
/* eslint-disable */
import casePages from '@/assets/js/cases.js'
export default {
    data() {
        return {
           activeName:'first',
        }
    },
    computed:{
        pages(){
         return casePages
        },
        manufacture(){
            let arr=[]
            arr.push(casePages[0],casePages[3],casePages[7],casePages[9],casePages[10],casePages[11],casePages[14],casePages[15])
            return arr
        },
        automobile(){
               let arr=[]
               arr.push(casePages[1],casePages[2])
               return arr
        },
        agriculture(){
               let arr=[]
               arr.push(casePages[8])
               return arr
        },
        pack(){
               let arr=[]
               arr.push(casePages[6],casePages[12],casePages[13],casePages[16],casePages[17])
               return arr
        },
        area(){
               let arr=[]
               arr.push(casePages[4],casePages[5])
               return arr
        },
    },
    mounted(){
        console.log(this.pack)
    }
}
</script>
<style lang="scss" scoped>
    .view-cases{
        .paragraphs{
            >div{
             width: 70%;
             margin:0.5rem  auto 0;   
            }
            .caseTitle{
                text-align: center;
                font-size: 0.3rem;
                font-weight: 600;
                color: #333;
            }
             .contents{
                display: flex;
                height: 2.5rem;
                margin-top: 0.5rem;
                justify-content: space-between;
                align-items: center;
                .image{
                    width:35%;
                    height: 100%;
                }
                .subContent{
                    width: 60%;
                }
              .right{
                  width: 35%;
              }
                .left{
                    width: 55%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
                .content{
                    font-size: 0.18rem;
                    font-weight: 400;
                    line-height: 0.3rem;
                    margin-bottom: 0.2rem;
                }
            }
        .cases{
            .subTitle{
               font-size: 0.2rem;
               color: #333;
               font-weight: bold;
               line-height: 0.35rem;
               margin-bottom: 0.2rem;
            }
                .left{
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .contents{
                    height: 2rem;
                }
                .top {
                    display: flex;
                    font-size:0.16rem;
                    .title{
                        text-align: center;
                    }
                    >div{
                        width: 48%;
                         height: 1.5rem;
                    }
                    .images{
                        display: block;
                        width: 90%;
                        height: 1.2rem;
                        margin:0.1rem auto 0;
                    }
                }
                .discription{
                    margin-top: 0.3rem;
                    text-align: center;
                    font-size: 0.16rem;
                }
            }
        }
    }
    
</style>
<style lang="scss" >
    .view-cases{
        .el-tabs__item {
            font-size: 0.2rem!important;
        }
        .el-tabs__nav {
         margin: auto;
        }
    }
</style>