/* eslint-disable */
export default [
  {
    subContent: {
      left: {
        title: "面膜检测",
        contents: [
          "检测结果:漏检≤0.3%|过杀≤1%",
          "缺陷类型:黑店、毛发、破损......",
        ],
        url: require("@/assets/images/view/cases/p1_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "破损",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p1_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p1_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "地铁轨道检测",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:弹条安装过度、弹条退弹、弹条缺失、螺栓丢失、轨距块丢失......",
        ],
        url: require("@/assets/images/view/cases/p2_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "弹条缺失",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p2_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p2_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "磁环检测",
        contents: [
          "检测结果:准确率98%",
          "缺陷类型:缺口、划痕、砂眼、脏污、倒角裂纹、附着......",
        ],
        url: require("@/assets/images/view/cases/p3_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "倒角裂纹",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p3_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p3_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "白酒杂质检测",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:黑点、毛发.....",
        ],
        url: require("@/assets/images/view/cases/p4_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "黑点毛发",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p4_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p4_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "光模块检测",
        contents: [
          "检测结果:准确率90%",
          "缺陷类型:漏铜、凹坑与划痕、碰伤、划痕.....",
        ],
        url: require("@/assets/images/view/cases/p5_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "凹坑与划痕",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p5_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p5_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "接线盒检测",
        contents: [
          "检测结果:准确率98%",
          "缺陷类型:锡片挂壁、二极管放反、立柱缺损.....",
        ],
        url: require("@/assets/images/view/cases/p6_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "倒角裂纹",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p6_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p6_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "洁柔纸巾外包装检测",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:开口线破损、形状严重变形、侧封严重漏纸、胶带侧封严重爆开、端口严重爆开、烫伤褶皱、平行四边形.....",
        ],
        url: require("@/assets/images/view/cases/p7_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "侧封严重漏纸",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p7_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p7_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "连接器检测",
        contents: [
          "检测结果:准确率95%",
          "缺陷类型:砂孔、多料、缺料......",
        ],
        url: require("@/assets/images/view/cases/p8_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "多料",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p8_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p8_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "米粒质检",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:爆腰、病斑、待定、断头、垩白、互混、黄、留胚留皮、碎、异品种.....",
        ],
        url: require("@/assets/images/view/cases/p9_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "碎",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p9_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p9_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "MiNi-LED检测",
        contents: [
          "检测结果:准确率99.5%",
          "缺陷类型:多胶、少胶、溢胶、胶体脱离、污染.…..",
        ],
        url: require("@/assets/images/view/cases/p10_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "溢胶",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p10_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p10_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "电池盖板缺陷检测",
        contents: [
          "检测结果:准确率95%",
          "缺陷类型:小平面毛刺、小平面倒角压伤、小平面多料、正反面压伤、大小孔堵孔、正反面划伤、中心孔多料、侧面压伤.…..",
        ],
        url: require("@/assets/images/view/cases/p11_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "正反面划伤",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p11_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p11_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "铁芯检测",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:铁芯破损长度不大于最短边长的1/2、目视可见破损位置不超过两处、铁芯破损长度大于最短边长的1/2、同一材料有两处或两处以上的破损....",
        ],
        url: require("@/assets/images/view/cases/p12_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "崩边",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p12_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p12_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "激光喷印检测",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:字符打印错位、字符打印不清晰、字符缺失.....",
        ],
        url: require("@/assets/images/view/cases/p13_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "字符打印错位",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p13_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p13_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "印刷品瑕疵检测",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:切边不良、印刷不良、墨水洒出、气泡.....",
        ],
        url: require("@/assets/images/view/cases/p14_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "切边不良",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p14_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p14_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "线圈检测",
        contents: [
          "检测结果:准确率95%",
          "缺陷类型:缠脚少圈、松线、线不入槽、毛丝、伤载体外形、倒三角、伤线、排线裂缝、线圈、内侧伤螺纹、胶水过量、绕线超过基准面、叠线、线头长、异物、绕线超出卡爪、.....",
        ],
        url: require("@/assets/images/view/cases/p15_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "崩边",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p15_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p15_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "光伏片检测",
        contents: [
          "检测结果:准确率95%",
          "缺陷类型:外观不良、赃污、印刷不良、断栅、指纹、隐裂、黑芯、....",
        ],
        url: require("@/assets/images/view/cases/p16_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "黑芯",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p16_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p16_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "塑料包装表面瑕疵检测",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:盒盖上出现小黑点、盒盖连接出出现小黑点、沐浴露外壳有“油污”、沐浴露外壳有异色小点、....",
        ],
        url: require("@/assets/images/view/cases/p17_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "油污",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p17_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p17_3.png"),
          }
        ]
      }
    }
  },
  {
    subContent: {
      left: {
        title: "吸管(白色)包装段AI视觉外观检测",
        contents: [
          "检测结果:准确率99%",
          "缺陷类型:吸管油污、黑点、薄膜油污、黑点、变形.....",
        ],
        url: require("@/assets/images/view/cases/p18_1.png"),
      },
      right: {
        discription: "瑕疵类型:",
        disContent: "油污",
        contents: [
          {
            title: "原图",
            url: require("@/assets/images/view/cases/p18_2.png"),
          },
          {
            title: "检测结果",
            url: require("@/assets/images/view/cases/p18_3.png"),
          }
        ]
      }
    }
  },
]
